module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":true},
    },{
      plugin: require('../../../node_modules/@phx-husky/gatsby-plugin-static-search/gatsby-browser.js'),
      options: {"plugins":[],"queries":[{"query":"{\n  allAboutScalp(filter: {hasChildren: {eq: false}}) {\n    nodes {\n      link\n      title\n      id\n      text\n      description\n    }\n  }\n}","indexName":"scalpicin-us","settings":{"searchableAttributes":["name","description"]}},{"query":"{\n  allLds {\n    nodes {\n      id\n      body\n      title\n      fields {\n        slug\n      }\n    }\n  }\n}","indexName":"scalpicin-us","settings":{"searchableAttributes":["name","description","keywords"]}},{"query":"{\n  products {\n    products {\n      name\n      packshot\n      url\n      id\n      keywords\n      description\n    }\n  }\n}","indexName":"scalpicin-us","settings":{"searchableAttributes":["name","description","keywords"]}},{"query":"{\n  faq {\n    id\n    url\n    name\n    faqQuestions {\n      properties {\n        answer\n        question\n      }\n    }\n  }\n}","indexName":"scalpicin-us","settings":{"searchableAttributes":["name","description","keywords"]}}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":0},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Scalpicin","short_name":"Scalpicin","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"/home/vsts/work/1/s/packages/gatsby-theme-husky/static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"db0b034e6897ba64d228c0345f57a7e9"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-8SWL","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../../gatsby-theme-husky/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{}},
    }]
